html,
body {
  margin: 0;
  height: 100%;
  background: #ffbb00;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1.1;
}

header {
  padding: 2rem 0;
}
header h1 {
  font-size: 6rem;
  text-align: center;
  line-height: 0.9;
}
header p {
  text-align: center;
  font-size: 1.4rem;
  margin: 1rem 0 0;
}
a {
  color: black;
}

h1 {
  text-align: center;
  font-size: 2rem;
}
h2 {
  font-size: 1.3rem;
}
h3 {
  font-size: 1rem;
}

p {
  font-size: 1rem;
  line-height: 1.4;
  margin: 0.5rem 0;
}

@media (min-width: 1024px) {
  header.container {
    padding: 6rem 0;
  }
}

header.container,
div.container {
  margin: 0 auto;
  width: 95%;
}
@media (min-width: 1200px) {
  header.container,
  div.container {
    width: 1024px;
  }
}
@media (min-width: 1024px) {
  header.container,
  div.container {
    width: 960px;
  }
  h1 {
    font-size: 8rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin: 2rem -0.5rem;
}
.flex .progress {
  padding: 0.5rem 0;
  box-sizing: border-box;
  margin: 0.25rem 0.5rem;
  width: calc(100% - 1rem);
}
@media (min-width: 375px) {
  .flex .progress {
    width: calc(50% - 1rem);
  }
}
.flex .progress.dark {
  background: black;
  color: #ffbb00;
  padding: 0.5rem;
}
@media (min-width: 780px) {
  .flex .progress {
    margin: 0.5rem;
    padding: 1rem 0;
    width: calc(25% - 1rem);
  }
  .flex .progress.dark {
    padding: 1rem;
  }
}

.timeline {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin: 4rem 0;
}
.timeline > div {
  margin: 0.5rem;
  width: calc(50% - 1rem);
}
@media (min-width: 780px) {
  .timeline > div {
    width: calc(25% - 1rem);
  }
}

svg {
  transform: rotate(-90deg);
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
}
svg circle {
  fill: none;
  stroke-width: 50px;
}
svg circle.prog {
  will-change: auto;
  stroke: #000;
}
svg circle.bg {
  stroke: rgba(0, 0, 0, 0.1);
}

.progress {
  display: flex;
  text-align: left;
}
.progress .left {
  width: 40px;
}
.progress .left,
.progress .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.progress .right {
  flex: 1;
  margin-left: 0.5rem;
}
